import { getLocalStorage } from '@/assets/js/storage.js';
import { getUUid } from '@/assets/js/tool.js';
import useDownLoad from '@/assets/js/use-download.js';
import { ref } from 'vue';
export default {
  props: {
    ide: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  setup(props) {
    const timer = ref(false);
    const {
      download
    } = useDownLoad();

    /**点击下载*/
    function clickDownLoad() {
      if (timer.value) clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        let sid = getLocalStorage('sid') || getUUid();
        let data = {
          sid: sid,
          id: props.ide.id,
          platform: props.ide.platform,
          downloadFileType: props.ide.downloadFileType
        };
        download(data);
      }, 200);
    }

    /**获取文本*/
    function getHtml(text) {
      return text.replace(/\n/g, '<br/>');
    }
    return {
      clickDownLoad,
      getHtml
    };
  }
};