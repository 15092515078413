import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { getDownloadList } from '@/service/index.js';
import { alertMessage } from '@/assets/js/tool.js';
import { ElLoading } from 'element-plus';
import VersionList from '@/components/VersionList';
import ideUpload from '@/components/Upload/IdeUpload';
import AppUpload from '@/components/Upload/AppUpload.vue';
export default {
  components: {
    VersionList,
    ideUpload,
    AppUpload
  },
  setup() {
    //data
    const {
      t
    } = useI18n();
    /**下载列表*/
    const downloadList = ref([]);
    /**ide下载数据*/
    const ideUpload = ref({});
    /**app下载数据*/
    const appUpload = ref({});
    /**当前选中*/
    const actived = ref('IDE');
    /**版本对象*/
    const versionData = ref({});
    /**加载中*/
    const loading = ElLoading.service({
      lock: true,
      background: 'rgba(0, 0, 0, 0)'
    });

    //获取下载列表
    getDownloadList().then(res => {
      loading.close();
      if (res.status === 0) {
        downloadList.value = res.data;
      } else {
        let text = res ? res.msg || t('failed.getData') : t('failed.getData');
        alertMessage('error', text);
      }
    });

    //watch
    watch(actived, newTab => {
      versionData.value = newTab === 'IDE' ? ideUpload.value : appUpload.value;
    });
    watch(downloadList, newList => {
      ideUpload.value = newList.find(item => item.latest && item.downloadFileType === 'IDE');
      let pc = newList.find(item => item.latest && item.downloadFileType === 'APP' && item.platform === 'Windows');
      let android = newList.find(item => item.latest && item.downloadFileType === 'APP' && item.platform === 'Android');
      let ios = newList.find(item => item.latest && item.downloadFileType === 'APP' && item.platform === 'IOS');
      appUpload.value = {
        pc,
        android,
        ios
      };
      versionData.value = actived.value === 'IDE' ? ideUpload.value : appUpload.value;
    });
    return {
      //data
      downloadList,
      actived,
      versionData,
      ideUpload,
      appUpload
    };
  }
};