import { getLocalStorage } from '@/assets/js/storage.js';
import { getUUid } from '@/assets/js/tool.js';
import useDownLoad from '@/assets/js/use-download.js';
import { ref } from 'vue';
export default {
  props: {
    app: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  setup(props) {
    const timer = ref(false);
    const {
      download
    } = useDownLoad();

    /**点击下载*/
    function clickDownLoad(type) {
      if (timer.value) clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        let sid = getLocalStorage('sid') || getUUid();
        if (type === 'ios') {
          window.open('https://apps.apple.com/cn/app/hcontrol/id1574489596?platform=ipad');
        } else {
          let result = props.app[type];
          let data = {
            sid: sid,
            id: result.id,
            platform: result.platform,
            downloadFileType: result.downloadFileType
          };
          download(data);
        }
      }, 200);
    }

    /**获取文本*/
    function getHtml(text) {
      return text.replace(/\n/g, '<br/>');
    }
    return {
      clickDownLoad,
      getHtml
    };
  }
};