import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e23c68c2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ide-upload"
};
const _hoisted_2 = {
  class: "description-text"
};
const _hoisted_3 = {
  class: "description-list"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('message.hcDescribe')), 1), _createElementVNode("div", {
    class: "btn",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.clickDownLoad && $setup.clickDownLoad(...args))
  }, _toDisplayString(_ctx.$t('message.download')), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('message.versionDescribe')) + "：", 1), _createElementVNode("p", {
    class: "item",
    innerHTML: $setup.getHtml($props.ide.description || '')
  }, null, 8, _hoisted_5)])]);
}