import { ref, watch } from 'vue';
import { ArrowRight } from '@element-plus/icons-vue';
export default {
  props: {
    type: {
      type: String,
      default: 'IDE'
    },
    version: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    ArrowRight
  },
  setup(props) {
    const logo = ref(null);
    logo.value = props.type === 'IDE' ? require('../assets/img/logo.png') : require('../assets/img/logo2.png');
    watch(() => props.type, () => {
      logo.value = props.type === 'IDE' ? require('../assets/img/logo.png') : require('../assets/img/logo2.png');
    });
    return {
      logo
    };
  }
};